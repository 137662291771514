.slider.slider-wrapper {
  margin-top: 0;
  margin-bottom: 0;
}
.slider .swiper-container {
  width: 100%;
}

.slider .swiper-slide {
  height: 100%;
  background: transparent;
  /*min-height: 20rem;*/
}

.slider .container-slider {
  width: 100%;
}

.slider .content-slider-wrapper {
  background: transparent;
  height: 100%;
  bottom: 0;
}
.content-slider-wrapper > .container {
  height: 100%;
}
.slider .slider-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  text-align: left;
  padding: 0.5rem;
}
.slider .slider-content h1,
.slider .slider-content h2 {
  line-height: 2.5rem;
  font-weight: 600;
  font-size: 2.3rem;
  color: var(--font-color);
  font-family: "Open Sans";
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.slider .slider-content b {
  font-family: "Open Sans";
  font-weight: bold;
}
.slider .slider-content p {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  color: var(--font-color);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.slider .slide--button-container {
  text-transform: none;
}
.slider .slider-img {
  width: 100%;
  display: block;
}
.slider .slider-img img {
  width: 100%;
  display: block;
}
.slider .slider-page .swiper-pagination-bullet {
  background: white;
  opacity: 1;
}
.slider .slider-page .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--color-primary);
}
@media screen and (min-width: 48rem) {
  .slider .swiper-slide {
    min-height: 20rem;
  }
}
@media screen and (min-width: 64rem) {
  .slider .slider-content {
    padding: 2rem;
  }
  .slider .slider-content p,
  .slider .slider-content h1,
  .slider .slider-content h2 {
    color: white;
  }
  .slider .container-slider {
    height: 100%;
  }
  .slider .content-slider-wrapper {
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .slider .slider-content {
    width: 50%;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
    text-align: left;
  }
  .slider .slider-content h2 {
    line-height: 3rem;
  }
  .slider .swiper-slide {
    min-height: 25rem;
  }
}
@media screen and (min-width: 64rem) {
  .slider .swiper-slide {
    min-height: auto;
  }
}

@media screen and (min-width: 80rem) {


}