.newsletter-content {
  background: white;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.newsletter-content h2 {
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}