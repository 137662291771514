header {
  background-size: auto;
  background: white;
  position: fixed;
  color: var(--font-color);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding-left: 0;
  padding-right: 0;
  box-shadow:0px 5px 24px 0px rgba(0,0,0, 0.13);
  -moz-box-shadow:0px 5px 24px 0px rgba(0,0,0, 0.13);
  -webkit-box-shadow:0px 5px 24px 0px rgba(0,0,0, 0.13);
}
.header--wrapper {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
}
.header-right {
  display: block;
  position: relative;
  width: 100%;
  margin-left: 0;
}

.header-slogan {
  display: none;
  text-align: left;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.6rem;
}

.header-logo-mobil {
  margin-left: 1rem;
  padding: 0.2rem;
}

.header-logo-mobil a {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.header-logo-mobil img {
  height: 3rem;
}

.hamburger-button svg {
  width: 2rem;
  height: 2rem;
  display: block;
  margin: auto auto;
}

.hamburger-button {
  height: 2.5rem;
  width: 2.5rem;
  outline: none;
  border: medium none;
  color: #f5f5f5;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 1rem;
}

.hamburger-button {
  background-color: var(--color-primary);
}

.hamburger-button:hover {
  background-color: color(var(--color-primary) a(80));
}

.header-logo {
  display: none;
}

.header-logo a {
  margin-left: 1rem;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.header-logo img {
  height: 6rem;
  margin: auto;
  width: auto;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
@media screen and (min-width: 48rem) {
  .header-logo {
    display: block;
  }
}
@media screen and (min-width: 64rem) {
  .header-slogan,
  .header-logo {
    display: block;
  }
  .header-right {
    margin-top: 1rem;
  }

}

@media screen and (min-width: 55rem) {
}
@media screen and (min-width: 64rem) {
  .header-logo img {
    height: 7rem;
    max-height: 7rem;
  }
}
@media screen and (min-width: 80rem) {
}

/* Header Search*/
.header-search {
  position: relative;
}
.header-search svg {
  height: 1rem;
  width: 1rem;
  display: none;
  fill: var(--color-primary);
}
.header-search:hover svg {
  fill: var(--color-primary);
}
.header-search:hover .search-wrapper {
  height: 4rem;
  opacity: 1;
  width: 22rem;
  right: -11rem;
  display: inherit;
}
input#search {
  margin: auto;
}


.search-wrapper button.pure-button {
  background: var(--color-primary);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem 1rem;
}
.search-wrapper .pure-form input[type="text"] {
  display: inline-block;
}
@media screen and (min-width: 48rem) {
  header {
    padding-left: 2rem;
    padding-right: 0rem;
  }

  .header-right {
    margin-left: 3rem;
  }
  .search-wrapper {
    z-index: 200;
    display: none;
    height: 4rem;
    opacity: 0;
    position: absolute;
    background: white;
    padding: 1rem;
    bottom: 0;
    width: 22em;
    top: 3rem;
    right: -300rem;
    box-shadow: -1px -1px 5px 5px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: -1px -1px 5px 5px rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: -1px -1px 12px 1px rgba(0, 0, 0, 0.19);
    transition: opacity 0.6s ease-in;
  }
  .search-triangle {
    position: absolute;
    top: -10px;
    left: 41%;
    width: 0px;
    height: 0px;
    -webkit-transform: rotate(360deg);
    border-style: solid;
    border-width: 0 12.5px 16.7px 12.5px;
    border-color: transparent transparent white transparent;
  }
  .header-search svg {
    display: block;
  }
  .search-click-div {
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    height:3rem;
  }
}

/* Header Info*/
.header-info {
  background: #f4f4f4;
  height: 8rem;
  padding-right: 2rem;
  padding-left: 1rem;
  width: 25rem;
  display: none;
  justify-content: flex-start;
  align-items: center;
  align-content:center;
  flex-wrap: wrap;
}
.header-info::before {
  content: '';
  position: absolute;
  z-index: 20;
  top: 0;
  left: -128px;
  width: 0px;
  height: 0px;
  -webkit-transform:rotate(360deg);
  border-style: solid;
  border-width: 0 0 128px 128px;
  border-color: transparent transparent #f4f4f4 transparent;
}
.header-info-element {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.header-info-element:last-child {
  margin-bottom: 0;
}

.header-info-element span {

}

.header-info-element svg {
  height: 1.4rem;
  width: 1.4rem;
  fill: var(--font-color);
  margin-top: 2px;
  margin-right: .7rem;
}

@media screen and (min-width: 90rem) {
  .header-info {
    display: flex;
    width: 30rem;
  }
}

.header-logo img {
  max-height: unset;
  height: auto;
  max-width: 300px;
}

.header--wrapper {
  align-items: flex-end;
}

.header-left,
.header-right {
    margin-top: 10px;
  margin-bottom: 10px;
}

/*.header-left {*/
/*  margin-top: 10px;*/
/*}*/
