.info-boxen {
  margin-top: var(--gutter);
  background: white;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.info-boxen  h2 {
  text-align: center;
  color: var(--color-secondary);
  padding-bottom: 3rem;
  position: relative;
  display: block;
}

.info-boxen  h2:before {
  content: ' ';
  position: absolute;
  height: 2px;
  background: #d7d7d7;
  width: 12rem;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
}

.info-boxen  h2:after {
  content: ' ';
  position: absolute;
  height: 2px;
  background: #d7d7d7;
  width: 6rem;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%);
}

.info-boxen .container {
  margin: 0 0.5rem;
}

@media screen and (min-width: 64rem) {
  .info-boxen .container {
    width: 64rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 80rem) {
  .info-boxen .container {
    width: 81rem;
  }
}



.info-boxen--wrapper {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  padding-bottom: 2rem;
}

.info-box {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.info-box > div{
  width: 100%;
}
.info-box h3{
  margin: 0;
  padding: 1rem;
  font-family: "Open Sans";
  font-weight: 700;
  color: var(--color-secondary);
}
.info-box .info-box--image {
  height: 30%;
}
.info-box .info-box--image img{
  width: auto;
  height: 10rem;
}
.info-box .info-box--content {
  padding: 1rem;
  line-height: 1.6rem;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
.info-box--link {
  margin-top: auto;
  display: block;
  width: 100%;
  padding-top: 2rem;
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
}
@media screen and (min-width: 48rem) {
  .info-box {
    width: calc(100% / 2 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;

  }
}

@media screen and (min-width: 64rem) {
  .info-box {
    width: calc(100% / 3 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

