.termin {
  background: white;
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.termin h3 {
  font-family: 'Papyrus';
  font-size: 1.5rem;
}

.termin .pure-g > div {
  padding-right: 1rem;
  padding-left: 1rem;
}

.termin .pure-g > div input,
.termin .pure-g > div select {
  margin-bottom: 2.5rem;
  border-radius: 0;

}
.termin .pure-g > div img{
  height: 90%;
  width: auto;
}
.termin-image {
  text-align: left;
}
input.pure-input-1.input-date {
  height: 2.56rem;
}
.btn-container {
  text-align: left;
}