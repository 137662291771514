.news {
  margin-top: var(--gutter);
}


.news .container {
  margin: 0 0.5rem;
}

@media screen and (min-width: 64rem) {
  .news .container {
    width: 61rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 80rem) {
  .news .container {
    width: 76rem;
  }
}



.news .boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  padding-bottom: 2rem;
}

.news--box {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  background: white;
}

.news--box h3{
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  text-transform: uppercase;
  font-family: "Open Sans Condensed";
  font-weight: 700;
  color: var(--color-primary);
}
.news--box .news--box--image img{
  width: 100%;
  height: auto;
}
.news--box .news--box--content {
  padding: 1rem;
}
.news--box--link {
  display: block;
  width: 100%;
  padding-top: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
@media screen and (min-width: 35.5rem) {
  .news--box {
    width: calc(100% / 2 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;

  }
}

@media screen and (min-width: 64rem) {
  .news--box {
    width: calc(100% / 3 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

