
.layout-3.content {
  text-align: left;
}

.layout-3 .content-right {
  margin-left: 0;
  position: relative;
  display: block;
}

.layout-3 .content-left {
  padding: 1rem;
  padding-left: 0;
  background: transparent;
  height: 100%;
  line-height: 1.6rem;
  font-weight: 400;
}
.layout-3 h2 {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}
.layout-3 .header-trenner {
  width: 4rem;
  text-align: left;
  height: 4px;
  background: var(--font-color);
  margin-bottom: 2.5rem;
  margin-left: 0;
}
@media screen and (min-width: 48rem) {
  .layout-3 .content-right {
    margin-left: 0.5rem;
  }
  .layout-3 {
    text-align: left;
  }
}