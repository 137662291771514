.kacheln {
  margin-top: var(--gutter);
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.kacheln  h2 {
  text-align: center;
  color: var(--color-secondary);
  padding-bottom: 3rem;
  position: relative;
  display: block;
}

.kacheln  h2:before {
  content: ' ';
  position: absolute;
  height: 2px;
  background: #d7d7d7;
  width: 12rem;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
}

.kacheln  h2:after {
  content: ' ';
  position: absolute;
  height: 2px;
  background: #d7d7d7;
  width: 6rem;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%);
}

.kacheln .container {
  margin: 0 0.5rem;
}

@media screen and (min-width: 64rem) {
  .kacheln .container {
    width: 64rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 80rem) {
  .kacheln .container {
    width: 81rem;
  }
}



.boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  padding-bottom: 2rem;
}

.leistungs-box {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  background: white;
}

.leistungs-box h3{
  margin: 0;
  padding: 1rem;
  font-family: "Open Sans";
  font-weight: 700;
  color: var(--color-secondary);
}
.leistungs-box .leistungs-box--image img{
  width: 100%;
  height: auto;
}
.leistungs-box .leistungs-box--content {
  padding: 1rem;
  line-height: 1.6rem;
}
.leistungs-box--link {

  display: block;
  width: 100%;
  padding-top: 2rem;
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
}
@media screen and (min-width: 48rem) {
  .leistungs-box {
    width: calc(100% / 2 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;

  }
}

@media screen and (min-width: 64rem) {
  .leistungs-box {
    width: calc(100% / 3 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

