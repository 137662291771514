.content {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
p.sub-titel {
  text-align: right;
  padding-top: 0.5rem;
}
.search article {
  margin-bottom: 1rem;
}

.search {
  margin-bottom: 2rem;
}


.content-mutiple-images a {
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}

.content ul {
  margin-left: 2rem;
  list-style: initial;
  padding: 0;
}


.content a {
  color: var(--color-primary);
}

.content a.btn-primary {
  color: white;
}

.content a:hover.btn-primary {
  color: white;
}

.content a:hover {
  color: color(var(--color-primary) a(60%));
}


.content > article {
  margin-bottom: calc(var(--gutter) * 2);
  margin-top: calc(var(--gutter) * 2);
}

.content .gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.content .gallery a {
  width: 48%;
  margin-right: 1%;
  display: block;
  position: relative;
}

.content .gallery a.content---image--one {
  width: 100%;
}
