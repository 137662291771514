.referenzen {
  background: transparent;
  padding: 2rem 0;
}
.refrenzen-container {
  margin: 0 0.5rem;
}

@media screen and (min-width: 64rem) {
  .refrenzen-container {
    width: 61rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 80rem) {
  .refrenzen-container{
    width: 76rem;
  }
}
.refrenzen--desc{
  background: white;
  padding: 1rem;
}
.refrenzen--desc h2 {
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}
.referenzen--boxen {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 1rem;
  flex-wrap: wrap;
  cursor: pointer;
}

.referenz--box {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.referenz--box::after {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  opacity: .4;
  transition: opacity .40s ease-out;
}

.referenz--box:hover::after {
  background: var(--color-primary);
  opacity: .4;
}

.referenz--box--content {
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: bottom .40s ease-in;
  z-index: 20;
}


.referenz--box--content h3 {
  color: var(--font-color);
  font-family: var(--font-light);
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: .7rem 0.5rem;
  background: rgba(255,255,255,0.8);
  width: auto;

}

.referenz-button {
  width: 3rem;
  height: 3rem;
  padding: 0;
  display: block;
  margin-left: auto;
}

.referenzen--link {
  padding: 2rem 0;
  text-align: center;
  width: 100%;
}

.referenzen--link a {
  font-family: var(--font-normal);
  font-size: 1.2rem;
}

/*.swiper-container.gallery-top  {
  width: 100%;
  height: 60vh;
}
.gallery-top .swiper-slide {
  text-align: center;
  font-size: 18px;
  !* Center slide text vertically *!
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
}*/

@media screen and (min-width: 35.5rem) {
  .referenzen--boxen {
    justify-content: flex-start;
  }

  .referenz--box {
    width: calc(100% / 2 - 1rem);
    overflow: hidden;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media screen and (min-width: 48rem) {
  .referenz--box {
    width: calc(100% / 3 - 1rem);
    overflow: hidden;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.referenz__content {
    padding: 16px;
    background: #fff;
}

.referenz__outer {
    padding: 0 10px 20px 10px;
}

.referenzen__list {
    margin: 0 -10px;
    overflow: hidden;
}
