/* first stage */

nav > ul {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

nav a {
  display: block;
  color: #fff;
  text-decoration: none;
}

nav ul li {
  color: #fff;
  list-style: none;
  transition: 0.5s;
  position: static;
}

nav > ul > li > a {
  padding: 0.5rem 0.7rem;
}
nav li > a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: #828385;
  border-bottom: solid 2px transparent;
}

nav > ul > li.active > a,
nav > ul > li.active > a:hover,
nav > ul > li:hover > a:hover,
nav > ul > li:hover > a {
  border-bottom: solid 2px var(--color-primary);
  color: var(--color-primary);
}
/* second stage (the mega-menu) */

nav ul.megamenu {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  min-width: 250px;
  top: -9999px;
  left: 0;
  padding: 0;
  background: white;
  text-align: left;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
  border-bottom: solid 2px var(--color-primary);
  border-top: solid 2px var(--color-primary);
}

nav ul li:hover ul.megamenu {
  top: 42px;
}

/* third stage (child-menus in the mega-menu) */
ul.second-level {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  box-shadow: 3px 5px 5px 5px rgba(0,0,0, 0.17);
  -moz-box-shadow:3px 5px 5px 5px rgba(0,0,0, 0.17);
  -webkit-box-shadow:3px 5px 5px 5px rgba(0,0,0, 0.17);
}

li.dropdown-list-item {
  width: 100%;
  margin-bottom: 0;
}
li.dropdown-list-item a {
  padding: 0.5rem;
  text-align: left;
  color: var(--font-color);
  font-size: 1.2rem;
}
nav > ul > li.dropdown-list-item a {
  padding-bottom: 2rem;
  padding-top: 2rem;
  color: var(--font-color);
}
li.dropdown-list-item a:hover {
  background: var(--color-primary);
  color: white;
}

button.hamburger-button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
}
ul.third-level {
  position:  absolute;
  left: 100%;
  background: white;
  margin:  0;
  padding: 0;
  width: 300px;
  overflow: hidden;
  top: 0;
  max-height: 0;
  transition: all;
}
ul.second-level > li:hover ul.third-level {
  border-top: solid 2px orange;
  max-height: 30000px;
  box-shadow: 3px 5px 5px 5px rgba(0,0,0, 0.17);
  -moz-box-shadow:3px 5px 5px 5px rgba(0,0,0, 0.17);
  -webkit-box-shadow:3px 5px 5px 5px rgba(0,0,0, 0.17);

}
