.meinungen-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: var(--color-primary);
  color: white;
}

.meinungen-wrapper h2 {
  display: block;
  text-transform: none;
  color: white;
  text-align: center;
  font-family: 'Papyrus';
  margin-top: 2rem;
  margin-bottom: 2rem;
}

div#mmeinungen {
  background: transparent;
  margin-left: 0;
}

.swiper-wrapper {
  background: transparent;
}

.swiper-slide.meinungs-slide {
  background: transparent;
}

.meinungen-img-wrapper {
  position: absolute;
  top: 0;
}

.meinungen-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.meinungen-right {
  display: none;
  position: relative;
  width: 100%;
}

.meinungen-left {
  width: 100%;
}
.meinungen-img-wrapper img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.meinungsbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  padding: .5rem 1rem;
  width: 100%;
  height: 100%;
}

.meinungen-img {
  width: 100%;
}

.meinungen-text {
  width: 100%;
  text-align: center;
  font-family: var(--font-light);
  height: 100%;
  position: relative;
  display: block;
}
.meinungen-bild {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  height: 7rem;
  width: 7rem;
  background: url("img/pattern.jpg");
  padding: 1rem;
  border-radius: 100rem;
}

.meinungen-bild svg {
  height: 5rem;
  width:  5rem;
  fill: var(--color-primary);
}

.meinungen-text p {
  margin-bottom: calc(var(--gutter) * 0.5);
}

.meinungen-name {
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.2;
  color: white;
  font-family: var(--font-light);
}

.meinunge-page span.swiper-pagination-bullet {
  background: transparent;
  border: 1px solid white;
  width: 0.7rem;
  height:0.7rem;
  opacity: 1;
}

.meinunge-page span.swiper-pagination-bullet-active {
  background: white;
}
@media screen and (min-width: 48rem) {
  .meinungen-text {
    width: 60%;
  }
  .meinungen-name::before,
  .meinungen-name::after {
    display: inline-block;
    content: "";
    border-top: 1px solid white;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-0.2rem);
  }
}