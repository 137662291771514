.contact-info {
 background: #f4f4f4;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.contact-info--textbild {
  position: relative;
  display: block;
  margin: 1rem;
  padding: 1rem;
  background: rgba(255,255,255,0.8);
  min-height: 25rem;
}

.contact-info--textbild h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight:600;
}

.contact-info--textbild p {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight:500;
}

.content .contact-info--textbild a {
  width: 100%;
  text-transform: uppercase;
  font-size: 1rem;
  padding-top: 2rem;
}
.contact-info--textinfo {
  background: white;
  padding: 2rem;
  height: 100%;
}
.contact-info--textinfo  h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight:600;
}
.contact-info--textinfo  p {
  padding: 0;
  margin: 0;
}
.contact-info--elment {
  margin-bottom: 2rem;
  font-size: 0.95rem;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.contact-info--elment:last-child {
  margin-bottom: 0;
}

.contact-info--elment span {

}

.contact-info--elment svg {
  height: 1rem;
  width: 1rem;
  fill: var(--font-color);
  margin-top: 5px;
  margin-right: .5rem;
}

.contact-info--image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 35.5rem) {
  .contact-info--textinfo {
    margin-left: 1rem;
  }
}
@media screen and (min-width: 48rem) {
  .contact-info--textbild {
    position: relative;
    display: block;
    margin: 2rem;
    width: 60%;
  }

}

@media screen and (min-width: 64rem) {
  .contact-info--textbild {
    width: 40%;
  }
}