/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

.pure-form fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

.btn {
  border-style: none;
  border-radius: 0;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: background .7s, color .7s;
  outline: none;
  display: inline-block;
}

.btn-primary {
  background: var(--color-primary);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0;
}

.btn-primary:hover {
  background: color(var(--color-primary) a(80%));
}

.btn-secondary {
  background: var(--color-secondary);
  color: white;
}

.btn-secondary:hover {
  background: color(var(--color-secondary) a(80%));
  color: white;
}

.pure-form input[type="text"], .pure-form input[type="password"], .pure-form input[type="email"], .pure-form input[type="url"], .pure-form input[type="date"], .pure-form input[type="month"], .pure-form input[type="time"], .pure-form input[type="datetime"], .pure-form input[type="datetime-local"], .pure-form input[type="week"], .pure-form input[type="number"], .pure-form input[type="search"], .pure-form input[type="tel"], .pure-form input[type="color"], .pure-form select,
.pure-form textarea {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  line-height: 1rem;

}
.pure-form input[type="text"], .pure-form input[type="password"], .pure-form input[type="email"], .pure-form input[type="url"], .pure-form input[type="date"], .pure-form input[type="month"], .pure-form input[type="time"], .pure-form input[type="datetime"], .pure-form input[type="datetime-local"], .pure-form input[type="week"], .pure-form input[type="number"], .pure-form input[type="search"], .pure-form input[type="tel"], .pure-form input[type="color"], .pure-form select {
  height: 35px;
}
#checkbox-ds {
  font-size: 0.7rem;
}

/* body select.select_box */
select {
  display: block;
  padding: 10px 70px 10px 13px !important;
  max-width: 100%;
  height: auto !important;
  border: 1px solid #e3e3e3;
  border-radius: 3px;

  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAlCAYAAAC6TzLyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RkI1ODEwNTg2QTdEMTFFNkJDQkZBMTU4M0NBNEM1NEIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RkI1ODEwNTc2QTdEMTFFNkJDQkZBMTU4M0NBNEM1NEIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVBMjdEOUZENDU4QjExRTVCRTREOTRCQjhFMkEyQjg1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjVBMjdEOUZFNDU4QjExRTVCRTREOTRCQjhFMkEyQjg1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+A5VqQwAAAOBJREFUeNrsljEKwjAUhkNRBBHcLB7BRXAruLi6desBegCv5AEcXDoVxLG4ONcjuHaygvELvEkcFFIC5QU+fl5eyEdDWmrqum6ttSYEkTFmaAKNyAQcKle5ylWu8v7JB78urKrqSoxgkyTJ/Ut/RpzhQX/l+8ljWECJaPohdnUp/biLY0+hgSUUCMcidlnIfCPr/Mo5yots3MIaDognLqV286ms83/h2PhEZPCCLdwkXZ1Jv7vbjuBI5FLOJXOZ7/5VQ7QndvB0KfX/g79XG/LvVT+vKle5ylWu8n7I3wIMAHzY7QF4JpH/AAAAAElFTkSuQmCC') right center no-repeat;
  background-color: #fff;
  color: var(--font-color);
  font-size: 12px;
  line-height: 16px !important;
  appearance: none;
  /* this is must */ -webkit-appearance: none;
  -moz-appearance: none;
}
/* body select.select_box option */
select option {
  padding: 0 4px;
}
/* for IE and Edge */
select::-ms-expand {
  display: none;
}
select:disabled::-ms-expand {
  background: #f60;
}

/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

input, textarea, select {
  margin-bottom: 1rem;
  font-size: 1rem;
  padding: 0.5rem;
  color: #000000;
}
select {
  padding: 0.75rem 0.5rem ;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #4a4a4a;
  opacity: 1;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #4a4a4a;
  opacity: 1;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #4a4a4a;
  opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
  color: #4a4a4a;
  opacity: 1;
}

.status-message {
  font-weight: bold;
  font-size: var(--font-size);
  margin-top: calc(var(--gutter)*0.5);
  color: #ffffff;
  padding: 0.5rem;
  display: none;
}
.status-message.error {
  background-color: var(--error);
  color: white;
}

.status-message.success {
  background-color: var(--success);
  color: white;
}
.status-message--show {
  display: block;
}