.product {
  background: white;
  margin: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.product-wrapper {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  padding-bottom: 2rem;
}

.product--slider {
  display: none;
  height: 465px;
  width: 100%;
  padding-bottom: 25px;

  justify-content: center;
  align-items: center;
  align-content: center;
}

.product-image {
  width: 100%;
  padding: 2rem;
  padding-top: 0;
  height: 100%;
  display: block;
}
.product--box {
  width: 100%;
  background: #f1f1f1;
  padding: 2rem;
  height: auto;
}

.product--slider .swiper-container {
  width: 100%;
  height: 100%;
}

.product--slider .swiper-slide {
  margin: auto;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.product--slider .product-sipwe .swiper-slide {
  height: 100px;
  width: 100px;
}

.product--slider .product-sipwe .slide-image {
  height: 100px;
  width: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #efefef;
  cursor: pointer;
}
.product--slider .product-sipwe .slide-image:hover {
  border: 2px solid var(--color-primary);
}
.product--slider .swiper-button-prev.product-prev {
  top: 0;
  transform: rotate(90deg);
  left: calc(50% - 7px);
  width: 15px;
  height: 20px;
  background-size: 15px 20px;
}

.product--slider .swiper-button-next.product-next {
  top: unset;
  bottom: 0;
  transform: rotate(90deg);
  left: calc(50% - 7px);
  width: 15px;
  height: 20px;
  background-size: 15px 20px;
}

.product--box h2 {
  font-weight: 600;
  text-transform: none;
}

.product--number {
  font-size: 0.9rem;
  margin: 0;
}

.product--energie {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product--energie a {
  color: var(--font-color);
}

.product--energie--badge {
  display: inline-block;
}

.product--energie--badge {
  margin-left: 1rem;
  position: relative;
  display: block;
  text-align: center;
  padding: 0 0.5rem;
  width: auto;
  height: 24px;
  font-weight: bold;
}

.product--energie--badge::before {
  content: '';
  width: 0px;
  height: 0px;
  -webkit-transform: rotate(360deg);
  border-style: solid;
  border-width: 12px 0 12px 12px;
  border-color: transparent transparent transparent white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translate(100%);
}

.product--energie--badge.color-AAAA {
  background-color: #00a652;
  color: white;
}

.product--energie--badge.color-AAAA::before {
  border-color: transparent transparent transparent #009037;
}
.product--energie--badge.color-AAA {
  background-color: #50b849;
  color: white;
}

.product--energie--badge.color-AAA::before {
  border-color: transparent transparent transparent #50b849;
}

.product--energie--badge.color-AA {
  background-color: #c0d731;
  color: white;
}

.product--energie--badge.color-AA::before {
  border-color: transparent transparent transparent #c0d731;
}

.product--energie--badge.color-A {
  background-color: #fff004;
  color: white;
}

.product--energie--badge.color-A::before {
  border-color: transparent transparent transparent #fff004;
}


.product--energie--badge.color-B {
  background-color: #fcb913;
  color: white;
}

.product--energie--badge.color-B::before {
  border-color: transparent transparent transparent #fcb913;
}

.product--energie--badge.color-C {
  background-color: #f37020;
  color: white;
}

.product--energie--badge.color-C::before {
  border-color: transparent transparent transparent #f37020;
}

.product--energie--badge.color-D {
  background-color: #ed1b24;
  color: white;
}

.product--energie--badge.color-D::before {
  border-color: transparent transparent transparent #ed1b24;
}

.product--energie--badge.color-E {
  background-color: #fabb01;
  color: white;
}

.product--energie--badge.color-E::before {
  border-color: transparent transparent transparent #fabb01;
}

.product--energie--badge.color-F {
  background-color: #eb680c;
  color: white;
}

.product--energie--badge.color-F::before {
  border-color: transparent transparent transparent #eb680c;
}

.product--energie--badge.color-G {
  background-color: #e2031a;
  color: white;
}

.product--energie--badge.color-G::before {
  border-color: transparent transparent transparent #e2031a;
}

.product--zoll {
  margin-top: 2rem;
}

.product--zoll--header {
  text-transform: uppercase;
  border-bottom: 1px solid #b5b5b5;
  font-size: 0.95rem;
  font-weight: 600;
}

.product--zoll-items {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.product--zoll--item {
  width: calc(50% - .5rem);
  margin-right: 0.5rem;
  margin-left: 0;
  margin-bottom: 1rem;
  background: white;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}
.product--zoll--item.active,
.product--zoll--item:hover {
  background: var(--color-primary);
  color: white;
}
.product--zoll--item:nth-of-type(2n) {
  margin-right: 0;
  margin-left: .5rem
}

.product--zoll--select {
  width: 100%;
  border-radius: 0;
  padding: 0.3rem 0.5rem;
  padding-right: 1rem;
  background-color: white;
  border-color: white;
}

.product--box--price {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.price--label {
  font-weight: 600;
  font-size: 1rem;
}

.price-value {
  font-size: 1.5rem;
  font-weight: 600;
}

.product--box--contact {
  margin-top: 1rem;
}

.product--attributes {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product--attribute {
  width: 100%;
  border-bottom: solid 1px #efefef;
  padding: 2rem;
}

.product--attribute img {
  max-width: 100%;
  width: 60%;
  margin: auto;
}

.product--attribute:last-child {
  border-right: none;
}

.product--desc {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f1f1f1;
}

.product--desc .toggle-link {
  width: 100%;
  text-align: center;
  padding: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.product--desc .toggle-link::after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiARMOFwWX45z7AAAAeElEQVQoz73Quw3CQBBF0eMEbQO7a4nMrdAApRJuAk340wEJFSAiAgsZo8UZvGx0r2Y0jz+kE76yoGtcPBzdq/hkR2tSKluCYtJCrigzzq8xG1dKUIwLhvSmzDh93kwGRRAUw4KblXJ2xd7Brf531OvFrdriNv5JnrdBGcdAJoBuAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTAxLTE5VDE0OjIzOjA1KzAxOjAwSmz96gAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wMS0xOVQxNDoyMzowNSswMTowMDsxRVYAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC');
  width: 25px;
  height: 23px;
  display: inline-block;
  position: relative;
  margin: auto;
  top: 0;
  left: 9px;
  transform: rotate(0deg);
  -webkit-animation: kdzRotate0 .4s 1 ease;
  -moz-animation: kdzRotate0 .4s 1 ease;
  -o-animation: kdzRotate0 .4s 1 ease;
  transition: all 0.4s linear;
}

.product--desc .toggle-link.active::after {
  transform: rotate(180deg);
  -webkit-animation: kdzRotate180 .4s 1 ease;
  -moz-animation: kdzRotate180 .4s 1 ease;
  -o-animation: kdzRotate180 .4s 1 ease;
}

@-webkit-keyframes kdzRotate180 {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(180deg); }
}
@-moz-keyframes kdzRotate180 {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(180deg); }
}
@-o-keyframes kdzRotate180 {
  from { -o-transform: rotate(0deg); }
  to { -o-transform: rotate(180deg); }
}
@-webkit-keyframes kdzRotate0 {
  from { -webkit-transform: rotate(180deg); }
  to { -webkit-transform: rotate(0deg); }
}
@-moz-keyframes kdzRotate0 {
  from { -moz-transform: rotate(180deg); }
  to { -moz-transform: rotate(0deg); }
}
@-o-keyframes kdzRotate0 {
  from { -o-transform: rotate(180deg); }
  to { -o-transform: rotate(0deg); }
}

@media screen and (min-width: 48rem) {
  .product-image img {
    display: block;
    height: auto;
    margin: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .product-wrapper {
    min-height: 600px;
  }
  .product--slider {
    width: 10%;
    display: flex;
  }
  .product-image {
    width: 60%;
  }
  .product--box {
    width: 30%;
  }

  .product--attribute {
    width: 25%;
    border-right: solid 1px #efefef;
    border-bottom: none;
  }
  .product--desc {
    padding: 2rem;
  }
}