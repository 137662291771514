.content-full {
  margin: 0;
}

.content-full .content-right {
  text-align: center;
  padding: 2rem 1rem;
}


.header-trenner {
  width: 100%;
  margin: auto;
  height: 1px;
  background: var(--font-color);
  margin-bottom: 1rem;
}