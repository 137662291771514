.hinweisbereich {
  margin-top: var(--gutter);
  background: white;
}


.hinweisbereich .container {
  margin: 0 0.5rem;
}

@media screen and (min-width: 64rem) {
  .hinweisbereich .container {
    width: 61rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 80rem) {
  .hinweisbereich .container {
    width: 76rem;
  }
}



.hinweis-boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  padding-bottom: 2rem;
}

.hinweis-box {
  width: 100%;
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: white;
  border-top: 2px solid #f3f3f3;
  padding-bottom: 1rem;


}
.hinweis-box h3,
.hinweis-box h4 {
  text-align: center;
}
.hinweis-box h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-transform: uppercase;
  color: var(--color-primary);
}
.hinweis-box h4 {
  font-family: "Papyrus";
  font-size: 1.4rem;
  font-weight: 300;
  margin-top: 0;
  padding-bottom: 1rem;
}
.hinweis-box p  {
  font-family: "Open Sans Condensed";
}
.hinweis-box ul  {
  font-family: "Open Sans Condensed";
}
.hinweis-box a {
  font-family:"Open Sans";
}
.hinweis-text {
  padding-left: 0.5rem;
}

#vn-bilder {
  display: block;
  position: relative;
  width: 100%;
  overflow-y: visible;
}
.vn-bilder-slide {
  width: 100%;
  display: block;
}
.hinweis-box--image-wrapper {
  display: flex;
  justify-content: flex-end;
}
.hinweis-box .hinweis-box--image {
  width: auto;
  display: inline-block;
  height: 100%;
  margin-left: auto;
}
.hinweis-box .hinweis-box--image img {
  height: 20rem;
}
.vn-bilder-slide::after,
.hinweis-box .hinweis-box--image::after  {
  content: ' ';
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  border: solid 1px white;
  z-index: 20000;
}
.vn-bilder-slide::after {
  bottom: 3.5rem;
}
.vn-bilder-slide img {
  padding-bottom: 3rem;
}
.hinweisbereich .hinweis-box .swiper-pagination-bullet {
  border: solid 1px #85678f;
  background: white;
  opacity: 1;
}
.hinweisbereich .hinweis-box .swiper-pagination-bullet-active {
  opacity: 1;
  background: #85678f;
}
.price-box {
  background: var(--color-primary);
  position: absolute;
  bottom: 5px;
  right: -10px;
  padding: 1rem;
  border-radius: 5px;
  color: white;
  z-index: 200000;
}
.price-box-tri {
  position: absolute;
  top: -8px;
  right: 0;
  width: 0px;
  z-index: 10000;
  height: 0px;
  -webkit-transform: rotate(360deg);
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent var(--color-secondary);
}
@media screen and (min-width: 35.5rem) {
  .hinweis-box {
    width: calc(100% / 2- 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;

  }
}

@media screen and (min-width: 64rem) {
  .hinweis-box {
    width: calc(100% / 2 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

