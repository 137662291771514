article.layout-1 {
  background: white;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.layout-1 h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Open Sans";
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  position: relative;
}
.layout-1 .header-trenner {
  width: 100%;
  margin: auto;
  height: 1px;
  background: var(--font-color);
  margin-bottom: 1rem;
}