.contact {
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: white;
}
.maps {
  background: white;
}
.maps-container {
  height: 25rem;
}

form#contact {
  width: 100%;

}

form#contact p:not(.status-message) {
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  color: #7c7c7c;
}

#label-daten {
  text-align: left;
  padding-left: 1rem;
}

#label-daten > span {
  padding-left: 1rem;
  display: inline-block;
}

.contact-div-left {
  text-align: left
}

input#contact-datenschutz {
  margin-bottom: auto;
  top: 6px;
  left: 0;
  position: absolute;
}

.contact--input-container {
  margin-bottom: 1rem;
}

.pure-form select {
  height: 2.56rem;
}

label {
  color: black;
  padding-bottom: 0;
  margin-bottom: 0;
}

.contact button {
  margin-top: 1rem;
  display: inline-block;
}

#contact-uhrzeit {
  margin-bottom: 0;
}

.status-message {
  font-weight: bold;
  font-size: var(--font-size);
  margin-top: calc(var(--gutter) * 0.5);
  color: #ffffff;
  padding: 0.5rem;
}

.status-message.error {
  background-color: var(--error);
  color: white;
}

.status-message.success {
  background-color: var(--success);
  color: white;
}

.contact {
  text-align: left;
}

.contact .content-right {
  margin-left: 0;
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;

}
.contact .content-left {
  padding: 1rem;
  background: white;
  height: 100%;
}
.contact h2 {
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}
@media screen and (min-width: 48rem) {
  .contact .content-right {
    margin-left: 0.5rem;
  }
}
@media screen and (min-width: 64rem) {

}


.meter {
  height: 20px; /* Can be anything */
  position: relative;
  background: #555;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  width: 100%;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  margin-top: 0.5rem;
}

.kontakt-form-upload p {
  width: 100%;
}

.kontakt-form-upload div {
  width: 100%;
}

.kontakt-form-upload {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.meter > span {
  display: block;
  height: 100%;
  border-radius: 0;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
      center bottom,
      rgb(43, 194, 83) 37%,
      rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
  inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}

.kdz-upload-btn-wrap {
  margin-bottom: 0;
}

.kontakt-form-files {
  margin-top: var(--gutter);
  margin-left: 0.5rem;
}

.kdz-upload-btn-wrap .btn {
  text-align: center;
}

.kontakt-form-files span.ion-trash-a {
  font-size: 1.2rem;
}

.pure-u-1-1.captcha-row {
  margin-left: -0.7rem;
  margin-bottom: 1rem;
}

#file-arbeit {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
}

#file-arbeit img {
  max-height: 10rem;
  width: auto;
}

#file-arbeit div {
  width: 100%;
  position: relative;
}

.delete-file {
  font-size: 1.5rem;
  z-index: 10;
  background-color: var(--error);
  padding: 0.5rem;
  position: absolute;
  bottom: 10px;
  left: 0;
  color: white;
}
#rep-send {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#rep-send img{
  height: 2rem;
  margin-right: 0.5rem;
}
.anfrage--table {
  border-spacing: 0;
}
.anfrage--btn {
  margin-top: 1rem;
}
.anfrage--btn:hover {
  color: white;
  background: color(var(--color-primary) a(90%));
}

#anfrage-from {
  padding-bottom: 4rem;
}

#anfrage-from .btn-primary {
  width: 100%;
}
@media screen and (--viewport-md) {
  .kontakt-form-upload p {
    width: 30%;
    margin-right: 1rem;
  }

  .kontakt-form-upload div {
    width: 68.5%;
  }

  #file-arbeit div {
    margin-left: 1rem;
    width: 25%;
    position: relative;
  }

  .pure-u-1-1.captcha-row {
    margin-left: 0;
    margin-bottom: 0;
  }
  #anfrage-from .btn-primary {
    width: auto;
    margin-left: auto;
  }

  .kontakt-content div {
    width: 25%;
    margin-bottom: 0;
  }
}

@media screen and (--viewport-xl) {
  .kontakt-content div {
    width: 25%;
    margin-bottom: 0;
  }
}