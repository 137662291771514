/* MAIN FOOTER*/
.main-footer {
  background: #555656;
  color: white;
  font-size: 0.95rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.main-footer a {
  color: white;
}

.main-footer a:hover {
  color: var(--color-primary);
}

.main-footer h3 {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 500;
}

.main-footer .contact-element {
  margin-bottom: 2.3rem;
  font-size: 0.95rem;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.main-footer .contact-element:last-child {
  margin-bottom: 0;
}

.main-footer .contact-element span {
  margin-left: .5rem;
}

.main-footer .contact-element svg {
  height: 1rem;
  width: 1rem;
  fill: white;
  margin-top: 5px;
}

.main-footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-footer ul li {
  padding: 0;
  margin-bottom: 0.3rem;
}

/* SubFooter*/
.sub-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  background: white;
  color: var(--font-color);
}

.sub-footer p {
  display: inline-block;
  width: auto;
}

.sub-footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: var(--font-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.sub-footer ul > li {
  color: var(--font-color);
  padding: .4rem;
}

.sub-footer ul > li {
  padding-left: 0;
}

.sub-footer a {
  color: var(--font-color);
}

.marken-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.marken-wrapper {
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
}

.marken-wrapper .marken-item {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.marken-wrapper .marken-item img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transition: all 0.5s ease;
}

.marken-wrapper .marken-item::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgb(244, 244, 244);
  opacity: 0.8;
  transition: all 0.5s ease;
}

.marken-wrapper .marken-item:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.marken-wrapper .marken-item:hover::after {
  opacity: 0;
}

.mitgliedschaften-wrapper {
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  padding-right: 6rem;
}

.mitgliedschaften-item {
  width: 45%;
  display: inline-block;
  position: relative;
}

.mitgliedschaften-item:last-of-type {
  width: 100%;
}

.icon-mail {
  padding: 0;
  margin: 0;
}

.icon-mail svg {
  display: block;
  width: 20px;
  height: 20px;
  fill: #5f7285;
}

.footer-newsletter input#newsletterMail {
  border-radius: 3px 0 0 3px;
  margin: 0;
}

.footer-newsletter svg#Capa_1 {
  margin: auto;
}

.footer-newsletter button#contactBottemSend {
  height: 2.2rem;
  width: 2.6rem;
  display: block;
  position: relative;
  background: white;
  box-shadow: none;
  border: white solid;
  border-radius: 0 3px 3px 0;
  outline: none;
  margin: 0;
}

.footer-newsletter form#newsletter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 0.5rem;
}

a.social-media {
  float: right;
  position: relative
}

a.social-media,
a.social-media svg {
  height: 1.4rem;
  width: 1.4rem
}

a.social-media svg {
  fill: #565656
}

a.social-media:hover svg {
  height: 1.4rem;
  width: 1.4rem;
  fill: #4267b2

}

@media screen and (min-width: 35.5rem) {

}

@media screen and (min-width: 48rem) {

  .marken-wrapper .marken-item {
    width: 17%;
    max-width: 17%;
  }

  .marken-footer-text {
    margin-right: 1rem;
    margin-bottom: 0rem;
  }

  .sub-footer ul > li ::after {
    position: relative;
    transform: translateY(-50%);
    content: '•';
    font-size: 1rem;
    margin: 0;
    padding: 0;
    line-height: 1rem;
  }

  .sub-footer ul > li:last-of-type ::after {
    content: '';
  }
}

.footer__col {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}

.footer__icon {
    font-size: 34px;
    background: var(--color-primary);
    border-radius: 50%;
    padding: 20px;
    margin-bottom: 20px;
}

.main-footer {
    background-color: #000;
}
