.team {
  background: #eee;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: block;
  position: relative;
}
.team h2{
  text-align: center;
}
.team-flex {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.team--box {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1%;

  background: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.team--box h3{
  margin-bottom: 0.5rem;
}
.team-content {
  padding: 1rem;
}
.team--postion {
  color: var(--color-primary);
}
@media screen and (min-width: 35.5rem) {
  .team--box {
    width: calc(98% / 2);
    margin-left: 0;
    margin-right: 1%;
  }
}
@media screen and (min-width: 50rem) {
  .team--box {
    width: calc(97% / 3);
    margin-left: 0;
    margin-right: 1%;
  }
}
@media screen and (min-width: 80rem) {
  .team--box {
    width: calc(96% / 4);
    margin-left: 0;
    margin-right: 1%;
  }
}