
.layout-2 {
  text-align: left;
}

.layout-2 .content-left  {
  margin-left: 0;
  position: relative;
  display: block;
}

.layout-2 .content-right {
  padding: 1rem;
  padding-right: 0;
  background: white;
  height: 100%;
  line-height: 1.6rem;
  font-weight: 400;
}
.layout-2 h2 {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}
.layout-2 .header-trenner {
  width: 4rem;
  text-align: left;
  height: 4px;
  background: var(--font-color);
  margin-bottom: 2.5rem;
  margin-left: 0;
}
@media screen and (min-width: 48rem) {
  .layout-2 .content-left {
    margin-right: 0.5rem;
  }
  .layout-2 {
    text-align: left;
  }
}