/*
 * Filename: base.css
 */
/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/open-sans-v17-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
    url('fonts/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-300.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('fonts/open-sans-v17-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
    url('fonts/open-sans-v17-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('fonts/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('fonts/open-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('fonts/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('fonts/open-sans-v17-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('fonts/open-sans-v17-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('fonts/open-sans-v17-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('fonts/open-sans-v17-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
* {
  position: relative;

  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  background: #f4f4f4;
 margin-top: 74px;
}

@media screen and (min-width: 48rem) {
    body {
        margin-top: 82px;
    }
}

@media screen and (min-width: 90rem) {
  body {
    margin-top: 8rem;
  }
}
.pure-img {
  width: 100%;
}

table {
  width: 100%;
  margin-bottom: var(--gutter);
}

th,
td {
  padding: .2rem;

  border-bottom: 1px solid var(--light-grey);
}

code {
  display: inline-block;

  padding: 0 .2em;

  color: var(--code);
  background: #eee;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.5s, background 0.5s;
}

a:not(.btn):hover {
  color: color(var(--color-secondary));
}


/** Firefox Glow bei invaliden Formularfeldern entfernen */
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

select {
  -webkit-appearance: none;
}
