.content-header {
  background: white;
  border-top: 10px solid var(--color-primary);
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.header-margin {
  height: 1rem;
}
.header-text {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222222+0,525252+100 */
  background: #222222; /* Old browsers */
  background: -moz-linear-gradient(left, #222222 0%, #525252 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #222222 0%,#525252 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #222222 0%,#525252 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222222', endColorstr='#525252',GradientType=1 ); /* IE6-9 */
}
.header-text h1{
  color: white;
  padding: 1rem 0;
  font-size: 2rem;
  line-height: 2rem;
  margin: 0;
}
.header-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.header-image--container {
  width: 100%;
}

.header-content {
  width: 100%;
 position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.header-content-inner {
  position: relative;
  width: 100%;
  padding: 1rem;
  padding-left: 2rem;
  text-align: left;
  margin: auto;
}
.header-content-inner h1 {
  font-family: var(--font-bold);
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 3rem;
}

.header-content-inner h1 {
  display: block;
  margin-top: 2rem;
}
.header-content-inner p {
  font-family: "Open Sans Condensed";
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 1.8rem;
}

.header-image--container img {
  width: 100%;
  display: block;
}
@media screen and (min-width: 35.5rem) {
}

@media screen and (min-width: 48rem) {

  .header-image--container {
    width: 60%;
  }

  .header-content {
    width: 40%;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .header-content-inner h1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media screen and (min-width: 64rem) {
  .header-content-inner h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
}

@media screen and (min-width: 80rem) {
  .header-content-inner h1 {
    font-size: 5rem;
    line-height: 7rem;
  }
}
