.video-container {
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  transform: translate(-15%, 0);
}

.video-back {
  height: 18rem;
  overflow: hidden;
}


.video-back--content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 30;
  background: color(var(--color-primary) a(80%));
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: white;
  text-align: center;
  width: auto;
  margin: auto;
}

.video-back--content h2 {
  margin-bottom: 2rem;
  display: inline-block;
  position: relative;
  margin-top: 0;
  color: white;
  font-family: var(--font-normal);
  font-size: 1.7rem;
}

.video-back-over .video-back--content h2 {
  color: white;
}

.video-back--content p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  font-family: var(--font-bold);
  line-height: 1.5rem;
}

@media screen and (min-width: 35.5rem) {

}

@media screen and (min-width: 48rem) {
  .video-container {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    z-index: 20;
    transform: translate(0, -22%);
  }

}
@media screen and (min-width: 64rem) {
  .video-back {
    height: 25rem;
  }
}