/*
 * Filename: typo.css
 *
 * Primary stylesheet which imports every other file.
 */

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?arfg70');
    src:  url('fonts/icomoon.eot?arfg70#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?arfg70') format('truetype'),
    url('fonts/icomoon.woff?arfg70') format('woff'),
    url('fonts/icomoon.svg?arfg70#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-wmf_location:before {
    content: "\e900";
}
.icon-wmf_phone:before {
    content: "\e901";
}
.icon-wmf_mail:before {
    content: "\e902";
}


body {
  font-family: var(--font-light);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 300;
  color: var(--font-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-top: 0;
  margin-bottom: var(--gutter-horizontal);
}

h1 {
  font-family: var(--font-bold);
  font-size: 2.6em;
  line-height: 3rem;
  font-weight: 900;
  color: var(--font-color);
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: var(--font-color);
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}
h3 {
  font-family: var(--font-normal);
  font-size: var(--font-size-subheading);
}
h4,
h5,
h6 {
  font-family: var(--font-normal);
}

small {
  font-size: var(--font-size-small);
}


.pure-g [class *= "pure-u"] {
  font-family: var(--font-normal);
}
.text-bold,
strong, b {
  font-weight: 900;
}

ul {
  padding-left: 24px;
}
li {
  margin-bottom: 0.4rem;
}
@media screen and (--viewport-md) {
}
