.faq {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.faq h2 {
  font-family: "Papyrus";
  font-size: 1.7rem;
  text-transform: none;
}
.faq--left {
  padding-left: 1rem;
}

.faq .pure-u-1{
  font-family: "Open Sans Condensed";
  font-weight: 500;
  font-size: 1.1rem;
}
.faq .content---image--one {
  padding: 1rem;
  padding-top: 0;
  display: block;
}

.faq  ul {
  margin-left: 1rem;
  list-style: none;
  padding: 0;
}

.faq  li {
  padding-left: 1.3em;
  font-family: "Open Sans";
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.faq  li:before {
  content: url("img/haken.png");
  display: inline-block;
  margin-left: -2rem; /* same as padding-left set on li */
  width: 2rem; /* same as padding-left set on li */
  transform: translateY(40%);
}

.faq .toggle-link {
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 1rem;
  margin-bottom: 0;
}
.faq .toggle-link:hover {
  color: var(--color-primary);
}
.faq .toggle-link::after {
  content: '(+)';
  padding-left: 0.5rem;
  color: var(--color-primary);
  display: inline-block;
}
.faq .toggle-link.active::after{
  content: '(-)';
  padding-left: 0.5rem;
  color: var(--color-primary);
  display: inline-block;
}