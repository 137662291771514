.listing {
  background: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.listing--box {
  margin-bottom: 2rem;
}

.listing--box--image {
  display: block;
  position: relative;
}
.listing--box--groessen {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translate(-50%);
}
.listing--box--groessen--inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.listing--box--groessen--elment {
  background: white;
  padding: 0.5rem;
  min-width: 2.3rem;
  text-align: center;
}
.listing--box--text {
  padding: 0.5rem 1rem;
}
.listing--box--text h2 {
  font-weight: 600;
  font-size: 1.7rem;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 2rem;
}

.listing--box--text {
  font-size: 0.9rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  height: 100%;
}
.listing--box--text ul {
  width: 100%;
}
.listing--datenblaetter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.listing--datenblaetter--icon {
  width: 3rem;
}
.listing--datenblaetter--icon svg {
  width: 3rem;
  height: 3rem;
}
.listing--datenblaetter--content {
  padding-left: 0.5rem;
}
.listing--datenblaetter--content p {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 0.9rem;
}
.listing--datenblaetter--links a {
  font-size: 0.9rem;
}

.listing--datenblaetter--links a::after{
  content: '|';
  display: inline-block;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  font-size: 0.9rem;

}
.btn--listing {
  margin-top: 2rem;
  display: block;
  width: 100%;
}
.btn--listing a {
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
}