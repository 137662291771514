.banner {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.banner--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
  flex-wrap: wrap;
}
.banner--box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  border: solid 2px #efefef;
  margin-bottom: 1rem;
}
.banner--box  h3 {
  text-transform: uppercase;
}
.banner--box-left {
  width: 100%;
  padding: 1rem;
}

.banner--box-right {
  width: 100%;
  height: 100%;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  display: none;
}
#handbuch {
  margin-top: 2rem;
}

@media screen and (min-width: 48rem) {
  .banner--box {
    width: 49%;
  }
  .banner--box-left {
    width: 60%;
  }
  .banner--box-right {
    display: block;
    width: 40%;
  }
}